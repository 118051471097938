<template>
    <!-- Cookie Policy -->
    <div class="terms" v-html="this.content"></div>
</template>

<script>
import { fetchData } from '@/utils/fetchData';
export default {
    mixins: [ fetchData ],
    data(){
        return {
            content: '',
        }
    },
    created(){
        this.fetchData('/getPolicy?page=cookie', (data)=>{
            this.content = data.policy.content;
        })
    }
}
</script>