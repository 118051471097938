<template>
    <div class="content_container policy">
        <nav class="tab_top">
            <router-link to="/policy/term">이용약관</router-link>
            <router-link to="/policy/privacy">개인정보처리방침</router-link>
            <router-link to="/policy/data">Data Policy</router-link>
            <router-link to="/policy/cookie">Cookie Policy</router-link>
        </nav>

        <router-view></router-view>
    </div>
    <Footer />
</template>

<script>
import Footer from "@/components/Footer";

export default {
    components: {
        Footer,
    },
}
</script>

<style src="@/assets/css/layout_policy.css">
</style>